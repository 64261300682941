import { Box, Flex } from '@radix-ui/themes';

// TODO : Add image for all events
const events = [
  {
    name: 'BSF - 2024',
    status: '25-29 Nov',
    imgUrl: '/assets/logos/BITSLogoShort.png',
    url: 'https://bsf.bitsdubaievents.com/',
  },
  {
    name: 'QbD - 2024',
    status: 'Completed',
    imgUrl: '/assets/logos/BITSLogoShort.png',
    url: 'https://qbd.bitsdubaievents.com/',
  },
  {
    name: 'JASHN - 2024',
    status: 'Completed',
    imgUrl: '/assets/logos/BITSLogoShort.png',
    url: 'https://jashn.bitsdubaievents.com/',
  },
  {
    name: 'R & I Day -2024',
    status: 'Completed',
    imgUrl: '/assets/logos/BITSLogoShort.png',
    url: 'https://researchday.bitsdubaievents.com/',
  },
  {
    name: 'STEAM - 2024',
    status: 'Completed',
    imgUrl: '/assets/logos/BITSLogoShort.png',
    url: 'https://steam.bitsdubaievents.com/',
  },
];

const Card = ({ imgUrl, name, status, url }) => (
  <a href={url} target='_blank' rel='noopener noreferrer'>
    <Box
      className='h-[120px] md:h-[150px] w-[250px] md:w-[400px] rounded-3xl hover:scale-105 transition-all duration-200 ease-in cursor-pointer p-5 bg-[#fdfdfd]'
      // style={{
      //   backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6)), url(${imgUrl})`,
      //   backgroundSize: 'cover',
      //   backgroundPosition: 'center',
      // }}
    >
      <Flex
        align='center'
        justify='center'
        direction='column'
        className='text-[#ffa800] text-center w-full h-full'
      >
        <p className='uppercase font-bold text-xl md:text-4xl'>{name}</p>
        <p className='font-bold text-base md:text-2xl'>{status}</p>
      </Flex>
    </Box>
  </a>
);

const Events = () => {
  return (
    <Box
      className='scroll-smooth overflow-x-scroll p-5 w-full'
      style={{
        scrollbarWidth: 'auto',
        scrollBehavior: 'smooth',
        scrollbarColor: '#FFA800 #FFEFC5',
      }}
    >
      <Flex
        width='max-content'
        align='start'
        direction='row'
        gap={{ initial: '4', md: '6' }}
      >
        {events.map((event) => (
          <Card
            id={event.name}
            name={event.name}
            imgUrl={event.imgUrl}
            status={event.status}
            url={event.url}
          />
        ))}
      </Flex>
    </Box>
  );
};

export default Events;

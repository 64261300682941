import { Container, Flex, Grid } from '@radix-ui/themes';

import Footer from './components/Footer';
import Events from './components/Events';

function App() {
  return (
    <main className='min-h-dvh w-screen flex flex-col justify-between gap-5 md:gap-10 bg-[#FFEFC5]'>
      <Container
        size='4'
        align='center'
        width='100%'
        mt={{ initial: '6', md: '9' }}
        p={{ initial: '5', lg: '0' }}
      >
        <Flex
          width='100%'
          direction='column'
          align='center'
          justify='center'
          gap={{ initial: '4', md: '6' }}
        >
          <Flex
            width='100%'
            direction='column'
            align='center'
            justify='center'
            gap={{ initial: '2', md: '4' }}
          >
            <img
              src='/assets/logos/BITSLogoShort.png'
              alt='BITSLogo'
              className='w-[70px] md:w-[100px] lg:w-[150px] h-auto'
            />

            <h1 className='text-xl md:text-3xl lg:text-5xl text-center'>
              BITS Pilani, Dubai Campus
            </h1>

            <h1 className='text-3xl md:text-6xl lg:text-8xl limelight-regular text-center'>
              STUDENT ACTIVITIES
            </h1>
          </Flex>

          <Events />

          <Grid
            align='center'
            gap={{ initial: '6', md: '6' }}
            columns={{ initial: '1', md: '2' }}
          >
            <div className='h-[200px] md:h-[300px] w-full bg-gray-500 rounded-3xl'>
              <iframe
                title='BPDC Map'
                loading='eager'
                allowFullScreen
                src='https://www.google.com/maps/embed/v1/place?q=place_id:ChIJg3YLr-xjXz4Rxzv2jtsDiRc&key=AIzaSyAeQn41vC5LzF_Wote1l6zB4DiCuvjrZ9s'
                className='h-full w-full rounded-3xl'
              />
            </div>

            <Flex
              align='center'
              direction='column'
              width='100%'
              height='100%'
              justify='center'
              gap={{ initial: '4', md: '6' }}
            >
              <h1 className='limelight-regular text-2xl md:text-4xl'>ABOUT</h1>

              <p className='text-[#00215A] text-lg md:text-xl font-semibold text-justify'>
                Welcome to the BITS Dubai Events page, your gateway to a variety
                of exciting activities at BITS Pilani, Dubai Campus. From sports
                and culture to research and innovation, our events offer
                opportunities to connect, collaborate, and showcase talent. Stay
                tuned for updates on all the exciting happenings at BITS Dubai!
              </p>
            </Flex>
          </Grid>
        </Flex>
      </Container>

      <Footer />
    </main>
  );
}

export default App;
